import { Divider, Heading } from "@chakra-ui/react"
import { WindowLocation } from "@reach/router"
import { Link } from "gatsby"
import React from "react"
import ContactForm from "../components/contactForm"
import Layout from "../components/layout/layout"

export default function Contact({ location }: { location: WindowLocation }) {
  return (
    <Layout title="Contact" location={location}>
      <div className="page">
        <Heading as="h1">Contact</Heading>
        <div>
          <br />
          <Heading as="h3" size="sm">
            De stalletjes site van Nederland
          </Heading>
          <p>
            Wij zijn Henk Jan, Joost en Iris, begin 2020 begonnen we met het
            verzamelen van boerderijwinkels, biologische plattelandswinkeltjes, bloemenkraampjes en stalletjes langs de weg met onbespoten groente en fruit in Nederland.
            We proberen actuele informatie te verzamelen zodat eten, drinken,
            cadeaugeschenken, plantjes enzovoorts langs de weg kopen nog leuker
            wordt. We trachten de adresjes zo goed mogelijk in kaart te brengen met duidelijke informatie over openingstijden, biologische wel/niet, onbespoten wel/niet en bezorging aan huis wel/niet.
          </p>
          <p>
            Fairsy is DE onafhankelijke site waar u stalletjes langs de weg en boerderijwinkels
            op het platteland vindt. Op Fairsy.nl ontdekt u meer dan 1750
            particulieren, boeren, kwekers enzovoorts met plattelandswinkels, streekproducten en
            kraampjes langs de weg. Bij deze kraampjes kunt u rechtstreeks
            (onbespoten) groente, heerlijk fruit, biologische producten, lokale
            cadeaus, verse bloemen, plantjes, honing, eieren van eigen kippen,
            zuivel e.d. kopen voor een eerlijke prijs. U steunt hiermee ook nog
            eens de locale ondernemer of particulier. U koopt lokaal en het is
            ook nog eens onwijs leuk.{" "}
          </p>
          <br />
          <Heading as="h3" size="sm">
            Volg alle vers uit de buurt
          </Heading>
          <p>
            Volg ons tevens op <Link to="/facebook/">Facebook</Link>, elke
            provincie heeft een eigen FB pagina, klik daarom eerst op een
            provincie. Klik op de <Link to="/over-ons">over ons</Link> pagina
            voor meer informatie over onze verzamelsite voor eten en drinken
            rechtstreeks kopen bij thuiskwekers, boeren en tuinders.
          </p>
          <br />
          <Heading as="h3" size="sm">
            Uw stalletje gratis promoten op Fairsy.nl
          </Heading>
          Wilt u ook uw stalletje langs de weg of boerderij winkel/kraampje kosteloos promoten op onze website? Of
          heeft u een leuk verkoopstalletje aan de straat gespot en wilt u ons
          tippen? Stuur een email naar fairsy24@gmail.com bij voorkeur inclusief
          foto('s) van het stalletje, adres, eventueel
          openingstijden/openingsdagen en korte omschrijving wat er te koop is
          in de kraam. Het is bovendien geheel gratis. Meer informatie vindt u op de pagina
          <Link to="/aanmelden/">aanmelden</Link>.
          <br />
          <br />
          <Heading as="h3" size="sm">
            Contact
          </Heading>
          Vragen? Email fairsy24@gmail.com (bij voorkeur)
        </div>
        <br />
        <Divider /> <br />
        <Heading as="h2" size="sm">
          Neem contact met ons op
        </Heading>
        <ContactForm />
      </div>
    </Layout>
  )
}
