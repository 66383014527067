import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Textarea,
  useToast,
} from "@chakra-ui/react"
import React from "react"
import { useForm } from "react-hook-form"

export default function ContactForm() {
  const toast = useToast()
  const onSubmit = (data: any) => {
    fetch(
      "https://prod-38.westeurope.logic.azure.com:443/workflows/0e1c5f39147a4fc78982c773d031f514/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=aAUh22OQxLAmMcIYzFudcAYBvAxysasDoG5p81NK8_o",
      {
        method: "POST", // *GET, POST, PUT, DELETE, etc.x
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",

          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify({
          ...data,
          url: document.location.toString(),
        }), // body data type must match "Content-Type" header
      }
    ).then(a => {
      toast({
        title: "Uw vraag is verstuurd.",
        description: " Wij streven er naar om binnen een week te reageren.",
        status: "success",
        duration: 9000,
        isClosable: true,
      })
    })
  }

  const { register, handleSubmit, errors } = useForm()

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div style={{ margin: "1rem 0" }}>
        <FormControl>
          <FormLabel htmlFor="naam">Uw naam</FormLabel>
          <Input
            type="text"
            aria-describedby="naam-helper-text"
            ref={register({ required: true })}
            placeholder="naam"
            name="naam"
          />
          <FormHelperText id="naam-helper-text"></FormHelperText>
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="email">Email adres</FormLabel>
          <Input
            name="email"
            type="email"
            id="email"
            placeholder="jandevries@dolfijn.nl"
            aria-describedby="email-helper-text"
            ref={register({ required: true })}
          />
          <FormHelperText id="email-helper-text"></FormHelperText>
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="opmerking">Vraag</FormLabel>
          <Textarea
            placeholder="alle feedback is welkom"
            name="opmerking"
            ref={register({ required: true })}
          />
        </FormControl>
      </div>
      <Button variantColor="teal" size="md" type="submit">
        Verstuur
      </Button>
    </form>
  )
}
